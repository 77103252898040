Array.from(document.querySelectorAll(".nav-main")).forEach((nav) => {
  nav
    .querySelector(".nav-main_btn button")
    ?.addEventListener("click", () =>
      nav.getAttribute("data-open")
        ? nav.removeAttribute("data-open")
        : nav.setAttribute("data-open", "true")
    );

  nav
    .querySelector(".nav-main_backdrop")
    ?.addEventListener("click", () => nav.removeAttribute("data-open"));
});
