Array.from(document.querySelectorAll(".comment-reply-section")).forEach(
  (section) => {
    const btn = section.querySelector("button");
    const form = section.querySelector(".comment-reply-form");
    if (!btn || !form) return;

    btn.addEventListener("click", () =>
      section.getAttribute("data-visible")
        ? section.removeAttribute("data-visible")
        : section.setAttribute("data-visible", "true")
    );

    section
      .querySelector("button[type=reset]")
      ?.addEventListener("click", () =>
        section.removeAttribute("data-visible")
      );
  }
);
